import React, { useState, useEffect, useRef } from 'react';
import '../assets/styles/pages/Filter.css';
import Results from '../components/Results';

const Filter = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [priceOption, setPriceOption] = useState('');
    const [price, setPrice] = useState('');
    const [priceUpper, setPriceUpper] = useState(''); // State for the upper price limit
    const [expirationDate, setExpirationDate] = useState('');
    const [dataSet, setDataSet] = useState([]); // Assuming this is your fullDataSet
    const [currentPage, setCurrentPage] = useState(1); // State to keep track of current page
    const [pageCount, setPageCount] = useState(1);

    const handlePageChange = (newPage) => {
      // Logic to change page
      setCurrentPage(newPage);
      // Potentially re-fetch data based on the new page
    };

    async function handleSubmit (e) {
      e.preventDefault();

      console.log("FORM SUBMITTED");

      // TODO: get symbol based on name ("Google" -> "GOOG")

      var min = 0;
      var max = Number.MAX_SAFE_INTEGER;

      if(priceOption === 'between') {
        min = price;
        max = priceUpper;
      }
      else if(priceOption === 'less_than') { 
        max = price;
      }
      else if(priceOption === 'more_than') { 
        min = price;
      }

      // set to true if the user hasn't chosen a date
      var undated = !expirationDate;

      // put all user input in a JSON
      const requestData = {
        symbol : searchTerm,
        date : expirationDate,
        priceLower : min,
        priceUpper : max,
        undated : undated
      }

      console.log("REQUEST DATA:");
      console.log(requestData);

      // FETCH request
      console.log("RESPONSE:")
      try {
        // promise
        const response = await fetch('https://glitter-backend.vercel.app/filter-endpoint/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          const json = await response.json();
          console.log(json.filtered);
  
          // Update state with the received data
          setDataSet(json.filtered);

          var pages = Math.ceil(json.filtered.length / 20);
          setPageCount(pages);

        } else {
          console.error('Error fetching data:', response.statusText);
        }
      }
      catch (e) {
        console.log(e);
      }
    };

    const handleReset = () => {
        setSearchTerm('');
        setPriceOption('');
        setPrice('');
        setPriceUpper('');
        setExpirationDate('');
        setDataSet([]);
        setCurrentPage(1);
        setPageCount(1);
    };

    const ref = useRef(null);

    useEffect(() => {
      makeFridayList();
    }, []);

    const makeFridayList = () => {
      // console.log("MAKING FRIDAY LIST");

      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const currentDate = new Date();
      currentDate.setHours(0);
      const dateSelect = ref.current;

      // prevent unneccessary appending from useEffect loading twice
      if(dateSelect.options.length > 53) {
        return;
      }

      while (currentDate.getDay() != 5) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      for (let i = 0; i < 52; i++) {
        let currentOption = document.createElement("option");
        currentOption.text = months[currentDate.getMonth()] + " " + currentDate.getDate() + " " + currentDate.getFullYear();
        currentOption.value = currentDate.toISOString().substring(0, 10); //YYYY-MM-DD

        dateSelect.add(currentOption);
        currentDate.setDate(currentDate.getDate() + 7);
      }
    }

    /*
    // const calculateRemainingFridays = () => {
    //   const currentDate = new Date();
    //   const currentYear = currentDate.getFullYear();
    //   const remainingFridays = [];
    //     for (let i = currentDate.getDay(); i < 7; i++) {
    //     const nextFriday = new Date(currentDate);
    //     nextFriday.setDate(currentDate.getDate() + (5 - currentDate.getDay() + i) % 7);
    //     if (nextFriday.getFullYear() === currentYear) {
    //       remainingFridays.push(nextFriday.toISOString().split('T')[0]);
    //     }
    //   }
    //   return remainingFridays;
    // };

    //Some autocomplete ideas:
    // var companies = [];
    // function autocompleteMatch(input) {
    //   if (input == '') {
    //       return [];
    //   }
    //   var reg = new RegExp(input)
    //   return companies.filter(function(term) {
	  //     if (term.match(reg)) {
  	//       return term;
	  //     }
    //   });
    // }

    // function showResults(val) {
    //   let res = document.getElementById("result");
    //   res.innerHTML = '';
    //   let list = '';
    //   let terms = autocompleteMatch(val);
    //   for (let i=0; i<terms.length; i++) {
    //     list += '<li>' + terms[i] + '</li>';
    //   }
    //   res.innerHTML = '<ul>' + list + '</ul>';
    // }

  //   function showResults(val) {
  //   let res = document.getElementById("result");
  //   res.innerHTML = '';
  //   if (val == '') {
  //     return;
  //   }
  //   let list = '';
  //   fetch('/suggest?q=' + val).then(
  //    function (response) {
  //      return response.json();
  //    }).then(function (data) {
  //      for (let i=0; i<data.length; i++) {
  //        list += '<li>' + data[i] + '</li>';
  //      }
  //      res.innerHTML = '<ul>' + list + '</ul>';
  //      return true;
  //    }).catch(function (err) {
  //      console.warn('Something went wrong.', err);
  //      return false;
  //    });
  // }
  */

  //Get Countries From Json File
  const searchcompany = async searchBox => {
  const res = await fetch('client/src/companyData.json');
  const companies = await res.json();
  
  //Get & Filter Through Entered Data
  let companySymbol = companies.filter(company => {
    const regex = new RegExp(`^${searchBox}`, 'gi');
    return company.symbol.match(regex);
    });
  };
  
  return (
      <div className="container">
        <form className="filter-bar" onSubmit={handleSubmit}>
          <input
            type="text"
            autoComplete="off"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
            className="input-field"
            required
          />
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Price"
            className="input-field"
          />
          {priceOption === 'between' && (
            <input
              type="number"
              value={priceUpper}
              onChange={(e) => setPriceUpper(e.target.value)}
              placeholder="To Price"
              className="input-field"
            />
          )}
          <select
            value={priceOption}
            onChange={(e) => setPriceOption(e.target.value)}
            className="input-field"
          >
            <option value="">Select condition</option>
            <option value="less_than">Less than</option>
            <option value="more_than">More than</option>
            <option value="between">Between</option>
          </select>

          <select 
            ref={ref}
            id="date-select"
            className="input-field"
            onChange={(e) => setExpirationDate(e.target.value)}
          >
            <option>Select date</option>
          </select>
          <button type="button" onClick={handleReset} className="reset-button">Reset Filter</button>      
          <button type="submit" className="search-button">Search</button>
        </form>

        <Results
            data={dataSet}
            itemsPerPage={20}
            currentPage={currentPage}
            totalPages={pageCount}
            onPageChange={handlePageChange}
        />
      </div>
    );
};
export default Filter;
